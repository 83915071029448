import { statisDimension } from '../const'

export default {
	header: {
		pic: 'statistical-analysis',
		title: '抄表率统计',
		desc: '可根据不同条件查看抄表的情况。',
	},
	filterArr: [
		{
			title: '统计维度',
			filterList: [
				{
					key: 'statisticsType',
					type: 'select',
					keyWord: '统计维度',
					multiple: true,
					needAll: false,
					clearable: true,
					options: statisDimension.meterRate,
				},
			],
		},
		{
			title: '统计条件',
			filterList: [
				{
					key: 'day',
					keyWord: 'N日抄表率',
					unit: '日',
				},
				{
					key: 'dateSection',
					type: 'date',
					keyWord: '时间范围',
					icon: 'icon-shijian',
					datePickerType: 'datetimerange',
				},
				{
					key: 'deviceTypeId',
					type: 'select',
					keyWord: '设备类型',
				},
				{
					key: 'manufacturerId',
					type: 'select',
					keyWord: '厂商名称',
				},
				// {
				//   key: "operator",
				//   type: "select",
				//   keyWord: "运营商",
				// },
				// {
				//   key: "residentType",
				//   type: "select",
				//   keyWord: "用户类型",
				// },
				// {
				//   key: "commModel",
				//   type: "select",
				//   keyWord: "通讯模式",
				// },
				{
					key: 'orgCode',
					type: 'select',
					keyWord: '机构',
				},
			],
		},
	],
	formRules: {
		day: [
			{
				pattern: /^[1-6]$/,
				message: '请输入1-6的整数',
				trigger: '',
			},
		],
	},
	columns: [
		{
			key: 'manufacturerName',
			name: '厂商名称',
		},
		// {
		//   key: "operator",
		//   name: "运营商",
		// },
		{
			key: 'meterReadingTime',
			name: '抄表日期',
		},
		{
			key: 'meterReadingRate',
			name: '抄表率',
		},
		{
			key: 'shouldMeterCount',
			name: '总表量',
		},
		{
			key: 'realMeterCount',
			name: '实抄表量',
		},
		{
			key: 'lastMonthNoCount',
			name: '休眠表量',
		},
		{
			key: 'outMeterReadingCount',
			name: '未抄表数',
		},
	],
}
